import MediaPlayer_videoBoxComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBox/viewer/skinComps/VideoBox/videoBox.skin';
import MediaPlayer_videoBoxController from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBox/viewer/VideoBox.controller';


const MediaPlayer_videoBox = {
  component: MediaPlayer_videoBoxComponent,
  controller: MediaPlayer_videoBoxController
};


export const components = {
  ['MediaPlayer_videoBox']: MediaPlayer_videoBox
};

